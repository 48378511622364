import React, { Fragment } from 'react'
import { Helmet } from 'react-helmet'

import '../css/base.css'

const IndexPage = () => (
  <Fragment>
    <Helmet>
      <title>Projekt Leuchtfeuer</title>
      <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/3.4.0/css/bootstrap.min.css" integrity="sha384-PmY9l28YgO4JwMKbTvgaS7XNZJ30MK9FAZjjzXtlqyZCqBY6X6bXIkM++IkyinN+" crossOrigin="anonymous" />
    </Helmet>
    <main>
      <div className="container">
        <div className="welcome">
          <h1>projekt leuchtfeuer</h1>
          <p className="lead">SoundCloud</p>
          <iframe width="100%" height="450" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1181265238&color=%689088&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"></iframe><div style={{ fontSize: "10px", color: "#cccccc",lineBreak: "anywhere", wordBreak: "normal", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis", fontFamily: "Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif", fontWeight: "100"}}><a href="https://soundcloud.com/projektleuchtfeuer" title="Projekt Leuchtfeuer" target="_blank" style={{color: "#cccccc", textDecoration: "none"}}>Projekt Leuchtfeuer</a> · <a href="https://soundcloud.com/projektleuchtfeuer/sets/demon-slayer" title="Demon Slayer" target="_blank" style={{color: "#cccccc", textDecoration: "none"}}>Demon Slayer</a></div>
          <article>
            <p className="h3">
              Projekt Leuchtfeuer bildet ein Musikkollektiv, das - bestehend aus vier Hauptmitgliedern - bemüht ist, zur Kontemplation über Themen ethisch-moralischen Charakters, den drängenden Fragen der Zeit sowie geistig-metaphysische Konzepte zu inspirieren und anzuregen. Die Kreativität der Gruppe entspringt dabei den Grundsätzen des Bahá’í-Glaubens und ihrem umfassenden Schrifttum, welches beansprucht der Welt einen Weg zur Vereinigung der gesamten Menschheit zu eröffnen. So ist die Identität der im Jahre 2006 entstandenen Musikgruppe auf der Vorstellung eines übernationalen Weltbürgertums und eines positiven Menschenbildes begründet – eine Identität welche im Kern von dem tiefen Glauben beseelt wird, dass die Erde nur ein Land ist und alle Menschen seine Bürger sind.  Da die Musik im Bahá’í-Ethos „als Leiter für die Seele“ begriffen wird, ist der Anspruch von Projekt Leuchtfeuer dementsprechend Musik zu schaffen, die den  Einzelnen durch ihre Texte und Klänge ermutigt, sich erhabene Ziele und hehre Ideale zu setzen sowie nach Exzellenz und Vortrefflichkeit zu streben. Auf diese Weise versteht Projekt Leuchtfeuer die eigene Musik als einen (wenn auch bescheidenen) Beitrag, durch den die Hörer sich eingeladen fühlen sollen, sich mit Fragen nach der Veredelung des Charakters sowie der Verbesserung der Gesellschaft auseinanderzusetzen. In diesem Sinne betrachtet Projekt Leuchtfeuer Kreativität, Musik und Gesang nicht als Selbstzweck, sondern als Mittel zu einem höheren Zweck, der, wie es die Bahá’í-Schriften darlegen, immerwährend das Handeln des Menschen und seine intrinsische Absicht durchdringen muss, nämlich das Wohl der Mitmenschen sowie die Belange der kollektiven Gesellschaft zu fördern.  Dies stellt den Maßstab dar, dem die einzelnen Mitglieder der Gruppe - demütig hoffend - bestrebt sein möchten, täglich gerechter werden zu dürfen.
            </p>
          </article>
        </div>
      </div>
    </main>
    
    <footer className="footer">
      <div className="container">
        <p className="copyright-text text-center"><strong>© {new Date().getFullYear()} Projekt Leuchtfeuer. All Rights Reserved.</strong></p>
      </div>
    </footer>
  </Fragment>
)

export default IndexPage
